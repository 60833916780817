var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "container-layout details-document-package",
            class: {
              "container-layout": _vm.$screen.width > 576,
              "container-mobile": _vm.$screen.width <= 576,
            },
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { staticClass: "px-0", attrs: { md: "12" } }, [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(55, "Document Details")))]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title cursor-pointer pt-0 pb-2 pl-0",
                                class: { "mt-16": _vm.$isPwa() },
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      on: { click: _vm.handleClickGoBack },
                                    },
                                    [
                                      _c(_vm.getIconLucide("ArrowLeft"), {
                                        tag: "component",
                                        staticClass: "icon",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.65)",
                                          size: 22,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "title-back-bolder mx-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(163, "Back")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("b-card-text", { staticClass: "pt-4" }, [
                              _c(
                                "div",
                                { staticClass: "px-1" },
                                [
                                  _vm.Docs
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", xl: "12" } },
                                            [
                                              _vm.newDoc
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card card-border-blue-light p-3",
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            10,
                                                                            "Object"
                                                                          ),
                                                                        "label-for":
                                                                          "docName",
                                                                        "label-cols":
                                                                          "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              id: "docName",
                                                                              disabled:
                                                                                _vm.isDocumentPackage ||
                                                                                _vm.disableByStatus(
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .status
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newDoc
                                                                                  .name,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newDoc,
                                                                                    "name",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newDoc.name",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            11,
                                                                            "Type"
                                                                          ),
                                                                        "label-for":
                                                                          "docType",
                                                                        "label-cols":
                                                                          "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.isDocumentPackage
                                                                        ? _c(
                                                                            "b-form-select",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  options:
                                                                                    _vm.optionsForDocType,
                                                                                  size: "md",
                                                                                  id: "docType",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.handleChangeType,
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .type,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "type",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.type",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    _vm.FormMSG(
                                                                                      421,
                                                                                      "Onboarding package"
                                                                                    ),
                                                                                  disabled:
                                                                                    "",
                                                                                },
                                                                            }
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  !_vm.isDocumentPackage
                                                                    ? _c(
                                                                        "b-row",
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _vm
                                                                                .newDoc
                                                                                .type ===
                                                                              9
                                                                                ? _c(
                                                                                    "b-form-group",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _vm.FormMSG(
                                                                                              13,
                                                                                              "File"
                                                                                            ),
                                                                                          "label-for":
                                                                                            "docFileBrowse",
                                                                                          "label-cols":
                                                                                            "3",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-file",
                                                                                        {
                                                                                          ref: "pdfFileId",
                                                                                          attrs:
                                                                                            {
                                                                                              "browse-text":
                                                                                                _vm.FormMSG(
                                                                                                  113,
                                                                                                  "Browse"
                                                                                                ),
                                                                                              name: "test",
                                                                                              id: "docFileBrowse",
                                                                                              accept:
                                                                                                ".docx,.pdf",
                                                                                              placeholder:
                                                                                                _vm.FormMSG(
                                                                                                  156,
                                                                                                  "Choose a file or drop it here..."
                                                                                                ),
                                                                                              "drop-placeholder":
                                                                                                _vm.FormMSG(
                                                                                                  157,
                                                                                                  "Drop file here..."
                                                                                                ),
                                                                                              disabled:
                                                                                                _vm.isDocumentPackage ||
                                                                                                _vm.disableByStatus(
                                                                                                  _vm
                                                                                                    .newDoc
                                                                                                    .status
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              _vm.submitDocFile,
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.pdfFile,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.pdfFile =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "pdfFile",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .newDoc
                                                                                .type !=
                                                                              9
                                                                                ? _c(
                                                                                    "b-form-group",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _vm.FormMSG(
                                                                                              13,
                                                                                              "File"
                                                                                            ),
                                                                                          "label-for":
                                                                                            "docFileBrowse",
                                                                                          "label-cols":
                                                                                            "3",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-file",
                                                                                        {
                                                                                          ref: "pdfFileId",
                                                                                          attrs:
                                                                                            {
                                                                                              "browse-text":
                                                                                                _vm.FormMSG(
                                                                                                  113,
                                                                                                  "Browse"
                                                                                                ),
                                                                                              name: "test",
                                                                                              id: "docFileBrowse",
                                                                                              accept:
                                                                                                ".jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt",
                                                                                              placeholder:
                                                                                                _vm.FormMSG(
                                                                                                  156,
                                                                                                  "Choose a file or drop it here..."
                                                                                                ),
                                                                                              "drop-placeholder":
                                                                                                _vm.FormMSG(
                                                                                                  157,
                                                                                                  "Drop file here..."
                                                                                                ),
                                                                                              disabled:
                                                                                                _vm.isDocumentPackage ||
                                                                                                _vm.disableByStatus(
                                                                                                  _vm
                                                                                                    .newDoc
                                                                                                    .status
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              _vm.submitDocFile,
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.pdfFile,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.pdfFile =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "pdfFile",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "b-row",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              !_vm.isDocumentPackage,
                                                                            expression:
                                                                              "!isDocumentPackage",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-start align-items-end",
                                                                          attrs:
                                                                            {
                                                                              cols: "7",
                                                                              offset:
                                                                                "3",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-group",
                                                                            {
                                                                              staticClass:
                                                                                "w-100",
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-left":
                                                                                        "-4px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .newDoc
                                                                                          .fileName,
                                                                                      placeholder:
                                                                                        _vm.FormMSG(
                                                                                          58,
                                                                                          "File name..."
                                                                                        ),
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.newDoc
                                                                        .fileName &&
                                                                      _vm.newDoc
                                                                        .fileName
                                                                        .length >
                                                                        0
                                                                        ? _c(
                                                                            "b-col",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-content-start align-items-center",
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-top":
                                                                                    "-12px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  cols: "2",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  class:
                                                                                    {
                                                                                      "btn-transparent text-color-burning-tomato":
                                                                                        _vm
                                                                                          .$screen
                                                                                          .width >
                                                                                        576,
                                                                                      "btn btn-danger mr-2":
                                                                                        _vm
                                                                                          .$screen
                                                                                          .width <=
                                                                                        576,
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm.isDocumentPackage ||
                                                                                        _vm.disableByStatus(
                                                                                          _vm
                                                                                            .newDoc
                                                                                            .status
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.removePdfFile,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Trash2"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 22,
                                                                                          "stroke-width": 1.5,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  class:
                                                                                    {
                                                                                      "btn-transparent text-color-rhapsody-in-blue":
                                                                                        _vm
                                                                                          .$screen
                                                                                          .width >
                                                                                        576,
                                                                                      "btn btn-primary":
                                                                                        _vm
                                                                                          .$screen
                                                                                          .width <=
                                                                                        576,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.handlePreviewDoc,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Eye"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 22,
                                                                                          "stroke-width": 1.5,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "6",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "pj-cb pb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "lg",
                                                                                  id: "toApp",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .availableOnline,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "availableOnline",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.availableOnline",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      14,
                                                                                      "View in App"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "6",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "pj-cb pb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "lg",
                                                                                  id: "toEmail",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .toEmail,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "toEmail",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.toEmail",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      15,
                                                                                      "Send by email"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-row",
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "6",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "pj-cb pb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "lg",
                                                                                  id: "toSms",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .toSms,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "toSms",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.toSms",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      41,
                                                                                      "Send SMS"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: "6",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "pj-cb pb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "lg",
                                                                                  id: "toWhatsApp",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .toWhatsApp,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "toWhatsApp",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.toWhatsApp",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      42,
                                                                                      "Send WhatsApp"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                      attrs: {
                                                                        "label-cols": 0,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-textarea",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id: "body",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  16,
                                                                                  "Enter message here..."
                                                                                ),
                                                                              "no-resize": true,
                                                                              state:
                                                                                _vm
                                                                                  .newDoc
                                                                                  .body &&
                                                                                _vm
                                                                                  .newDoc
                                                                                  .body
                                                                                  .length <
                                                                                  4000,
                                                                              rows: "5",
                                                                              disabled:
                                                                                _vm.isDocumentPackage ||
                                                                                _vm.disableByStatus(
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .status
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newDoc
                                                                                  .body,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newDoc,
                                                                                    "body",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newDoc.body",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "divider",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "sub-title mb-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        168,
                                                                        "Options"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              !_vm.isDocumentPackage,
                                                                            expression:
                                                                              "!isDocumentPackage",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "pj-cb pb-1",
                                                                          attrs:
                                                                            {
                                                                              size: "lg",
                                                                              id: "toSign",
                                                                              disabled:
                                                                                _vm.isDocumentPackage ||
                                                                                _vm.disableByStatus(
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .status
                                                                                ) ||
                                                                                _vm.disableToSignByStep ||
                                                                                _vm
                                                                                  .newDoc
                                                                                  .requireManagerApproval,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleChangeToSign,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newDoc
                                                                                  .toSign,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newDoc,
                                                                                    "toSign",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newDoc.toSign",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  315,
                                                                                  "To sign by user"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "pj-cb pb-1",
                                                                          attrs:
                                                                            {
                                                                              size: "lg",
                                                                              id: "requireSignManager",
                                                                              disabled:
                                                                                _vm.isDocumentPackage ||
                                                                                _vm.disableSelectRequireApproval ||
                                                                                _vm.disableByStatus(
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .status
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleChangeManagerApproval,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newDoc
                                                                                  .requireManagerApproval,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newDoc,
                                                                                    "requireManagerApproval",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newDoc.requireManagerApproval",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  169,
                                                                                  "Require approval / manager signature"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-collapse",
                                                                {
                                                                  attrs: {
                                                                    visible:
                                                                      _vm.newDoc
                                                                        .requireManagerApproval,
                                                                  },
                                                                },
                                                                [
                                                                  _vm.newDoc
                                                                    .requireManagerApproval
                                                                    ? _c(
                                                                        "b-row",
                                                                        {
                                                                          staticClass:
                                                                            "mt-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _c(
                                                                                "fieldset",
                                                                                {
                                                                                  staticClass:
                                                                                    "card-inside",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "legend",
                                                                                    {
                                                                                      staticClass:
                                                                                        "card-inside",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              152,
                                                                                              "Approval steps"
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "container-field",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "choice-step-document",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              "is-disabled":
                                                                                                _vm.isDocumentPackage ||
                                                                                                _vm.disableByStatus(
                                                                                                  _vm
                                                                                                    .newDoc
                                                                                                    .status
                                                                                                ),
                                                                                              document:
                                                                                                _vm.newDoc,
                                                                                            },
                                                                                          on: {
                                                                                            "choice-step-document:change":
                                                                                              _vm.handleChoiceStepDocumentChange,
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.isPdfFile()
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "divider",
                                                                  }),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "sub-title mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            167,
                                                                            "Watermark options"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "b-row",
                                                                    {
                                                                      staticClass:
                                                                        "pb-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "12",
                                                                              md: "12",
                                                                              lg: "12",
                                                                              xl: "12",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "pj-cb pb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "lg",
                                                                                  id: "watermark",
                                                                                  disabled:
                                                                                    _vm.isDocumentPackage ||
                                                                                    _vm.disableByStatus(
                                                                                      _vm
                                                                                        .newDoc
                                                                                        .status
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newDoc
                                                                                      .useWatermark,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newDoc,
                                                                                        "useWatermark",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newDoc.useWatermark",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      21,
                                                                                      "Add Watermark"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-collapse",
                                                                    {
                                                                      attrs: {
                                                                        visible:
                                                                          _vm
                                                                            .newDoc
                                                                            .useWatermark,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "container-field",
                                                                        },
                                                                        [
                                                                          _vm.isPdfFile() &&
                                                                          _vm
                                                                            .newDoc
                                                                            .useWatermark
                                                                            ? _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "b-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          sm: "12",
                                                                                          md: "6",
                                                                                          lg: "6",
                                                                                          xl: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-group",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                _vm.FormMSG(
                                                                                                  22,
                                                                                                  "Watermark text"
                                                                                                ),
                                                                                              "label-for":
                                                                                                "watermarkText",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-input",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id: "watermarkText",
                                                                                                  disabled:
                                                                                                    _vm.isDocumentPackage ||
                                                                                                    _vm.disableByStatus(
                                                                                                      _vm
                                                                                                        .newDoc
                                                                                                        .status
                                                                                                    ),
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .newDoc
                                                                                                      .watermarkText,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.newDoc,
                                                                                                        "watermarkText",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "newDoc.watermarkText",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .useWatermark
                                                                                    ? _c(
                                                                                        "b-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex justify-content-start align-items-end",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-bottom":
                                                                                                "12px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              sm: "12",
                                                                                              md: "6",
                                                                                              lg: "6",
                                                                                              xl: "6",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-checkbox",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pj-cb pb-1",
                                                                                              attrs:
                                                                                                {
                                                                                                  size: "lg",
                                                                                                  id: "withusername",
                                                                                                  disabled:
                                                                                                    _vm.isDocumentPackage ||
                                                                                                    _vm.disableByStatus(
                                                                                                      _vm
                                                                                                        .newDoc
                                                                                                        .status
                                                                                                    ),
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .newDoc
                                                                                                      .watermarkWithUser,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.newDoc,
                                                                                                        "watermarkWithUser",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "newDoc.watermarkWithUser",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                  _vm._s(
                                                                                                    _vm.FormMSG(
                                                                                                      23,
                                                                                                      "With user name"
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm.isPdfFile() &&
                                                                          _vm
                                                                            .newDoc
                                                                            .useWatermark
                                                                            ? _c(
                                                                                "b-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "pb-1",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-col",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          sm: "12",
                                                                                          md: "6",
                                                                                          lg: "6",
                                                                                          xl: "6",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-group",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                _vm.FormMSG(
                                                                                                  24,
                                                                                                  "Watermark image"
                                                                                                ),
                                                                                              "label-for":
                                                                                                "watermarkImage",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-file",
                                                                                            {
                                                                                              ref: "wmFileFieldId",
                                                                                              attrs:
                                                                                                {
                                                                                                  "browse-text":
                                                                                                    _vm.FormMSG(
                                                                                                      113,
                                                                                                      "Browse"
                                                                                                    ),
                                                                                                  accept:
                                                                                                    ".jpg,.png,.gif",
                                                                                                  id: "watermarkImage",
                                                                                                  placeholder:
                                                                                                    _vm.FormMSG(
                                                                                                      212,
                                                                                                      "Choose watermark image or drop it here..."
                                                                                                    ),
                                                                                                  "drop-placeholder":
                                                                                                    _vm.FormMSG(
                                                                                                      213,
                                                                                                      "Drop file here..."
                                                                                                    ),
                                                                                                  disabled:
                                                                                                    _vm.isDocumentPackage ||
                                                                                                    _vm.disableByStatus(
                                                                                                      _vm
                                                                                                        .newDoc
                                                                                                        .status
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                change:
                                                                                                  _vm.submitWmFile,
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm.wmImageFile,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.wmImageFile =
                                                                                                        $$v
                                                                                                    },
                                                                                                  expression:
                                                                                                    "wmImageFile",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "b-col",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex justify-content-start align-items-end",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-bottom":
                                                                                            "-3px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          sm: `${
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName &&
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName
                                                                                              .length >
                                                                                              0
                                                                                              ? 10
                                                                                              : 12
                                                                                          }`,
                                                                                          md: `${
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName &&
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName
                                                                                              .length >
                                                                                              0
                                                                                              ? 4
                                                                                              : 6
                                                                                          }`,
                                                                                          lg: `${
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName &&
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName
                                                                                              .length >
                                                                                              0
                                                                                              ? 4
                                                                                              : 6
                                                                                          }`,
                                                                                          xl: `${
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName &&
                                                                                            _vm
                                                                                              .newDoc
                                                                                              .watermarkPictureFileName
                                                                                              .length >
                                                                                              0
                                                                                              ? 4
                                                                                              : 6
                                                                                          }`,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-group",
                                                                                        {
                                                                                          staticClass:
                                                                                            "w-100",
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-input",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .newDoc
                                                                                                      .watermarkPictureFileName,
                                                                                                  placeholder:
                                                                                                    _vm.FormMSG(
                                                                                                      58,
                                                                                                      "File name..."
                                                                                                    ),
                                                                                                  disabled:
                                                                                                    "",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .watermarkPictureFileName &&
                                                                                  _vm
                                                                                    .newDoc
                                                                                    .watermarkPictureFileName
                                                                                    .length >
                                                                                    0
                                                                                    ? _c(
                                                                                        "b-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex justify-content-start align-items-center",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-bottom":
                                                                                                "-16px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              sm: "2",
                                                                                              md: "2",
                                                                                              lg: "2",
                                                                                              xl: "2",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "button",
                                                                                            {
                                                                                              class:
                                                                                                {
                                                                                                  "btn-transparent text-color-burning-tomato":
                                                                                                    _vm
                                                                                                      .$screen
                                                                                                      .width >
                                                                                                    576,
                                                                                                  "btn btn-danger mr-2":
                                                                                                    _vm
                                                                                                      .$screen
                                                                                                      .width <=
                                                                                                    576,
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.isDocumentPackage ||
                                                                                                    _vm.disableByStatus(
                                                                                                      _vm
                                                                                                        .newDoc
                                                                                                        .status
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  _vm.removeWmFile,
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                _vm.getIconLucide(
                                                                                                  "Trash2"
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      size: 22,
                                                                                                      "stroke-width": 1.5,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "button",
                                                                                            {
                                                                                              class:
                                                                                                {
                                                                                                  "btn-transparent text-color-rhapsody-in-blue":
                                                                                                    _vm
                                                                                                      .$screen
                                                                                                      .width >
                                                                                                    576,
                                                                                                  "btn btn-primary":
                                                                                                    _vm
                                                                                                      .$screen
                                                                                                      .width <=
                                                                                                    576,
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  _vm.handlePreviewWm,
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                _vm.getIconLucide(
                                                                                                  "Eye"
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      size: 22,
                                                                                                      "stroke-width": 1.5,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "b-collapse",
                                                            {
                                                              attrs: {
                                                                visible:
                                                                  _vm.showPasswordOptions,
                                                              },
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "divider",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "sub-title mb-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          198,
                                                                          "Password options"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "container-field",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-group",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.FormMSG(
                                                                                        25,
                                                                                        "Pdf User password"
                                                                                      ),
                                                                                    "label-for":
                                                                                      "UserPwd",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-input-group",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-4",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-input",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            autocomplete:
                                                                                              "nope",
                                                                                            type: !_vm.showTextConfirmPassword
                                                                                              ? "password"
                                                                                              : "text",
                                                                                            id: "UserPwd",
                                                                                            placeholder:
                                                                                              _vm.FormMSG(
                                                                                                214,
                                                                                                "If protected, enter document user password ."
                                                                                              ),
                                                                                            disabled:
                                                                                              _vm.isDocumentPackage,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .newDoc
                                                                                                .userPassword,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.newDoc,
                                                                                                  "userPassword",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "newDoc.userPassword",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "b-input-group-append",
                                                                                      [
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "btn-show-password",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "button",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.showTextConfirmPassword =
                                                                                                    !_vm.showTextConfirmPassword
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            !_vm.showTextConfirmPassword
                                                                                              ? _c(
                                                                                                  _vm.getIconLucide(
                                                                                                    "Eye"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#9c9d9e",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm.showTextConfirmPassword
                                                                                              ? _c(
                                                                                                  _vm.getIconLucide(
                                                                                                    "EyeOff"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#9c9d9e",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-group",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.FormMSG(
                                                                                        26,
                                                                                        "Pdf Owner password"
                                                                                      ),
                                                                                    "label-for":
                                                                                      "OwnerPwd",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-input-group",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-4",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-input",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: !_vm.showTextPassword
                                                                                              ? "password"
                                                                                              : "text",
                                                                                            id: "OwnerPwd",
                                                                                            placeholder:
                                                                                              _vm.FormMSG(
                                                                                                216,
                                                                                                "Enter document owner password."
                                                                                              ),
                                                                                            disabled:
                                                                                              _vm.isDocumentPackage,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .newDoc
                                                                                                .ownerPassword,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.newDoc,
                                                                                                  "ownerPassword",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "newDoc.ownerPassword",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "b-input-group-append",
                                                                                      [
                                                                                        _c(
                                                                                          "button",
                                                                                          {
                                                                                            staticClass:
                                                                                              "btn-show-password",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "button",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.showTextPassword =
                                                                                                    !_vm.showTextPassword
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            !_vm.showTextPassword
                                                                                              ? _c(
                                                                                                  _vm.getIconLucide(
                                                                                                    "Eye"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#9c9d9e",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm.showTextPassword
                                                                                              ? _c(
                                                                                                  _vm.getIconLucide(
                                                                                                    "EyeOff"
                                                                                                  ),
                                                                                                  {
                                                                                                    tag: "component",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "#9c9d9e",
                                                                                                        size: 16,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          !_vm.isDocumentPackage ||
                                                          !_vm.disableByStatus(
                                                            _vm.newDoc.status
                                                          )
                                                            ? _c(
                                                                "b-row",
                                                                {
                                                                  staticClass:
                                                                    "pt-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-content-center align-items-center",
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _c(
                                                                            "h6",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  this
                                                                                    .lastPublishedOn
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.newDoc
                                                                        .type ===
                                                                      9
                                                                        ? _c(
                                                                            "b-form-group",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-primary",
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "success",
                                                                                      size: "md",
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.downloadPDF()
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        this.FormMSG(
                                                                                          20,
                                                                                          "Download mail merge fields..."
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-primary btn-md float-right",
                                                                              attrs:
                                                                                {
                                                                                  variant:
                                                                                    "primary",
                                                                                  size: "md",
                                                                                  disabled:
                                                                                    _vm.disableSave,
                                                                                  block:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.curDocUpd()
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    this.FormMSG(
                                                                                      18,
                                                                                      "Save modifications"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-modal",
                                                        {
                                                          staticClass:
                                                            "modal-success",
                                                          attrs: {
                                                            title: _vm.FormMSG(
                                                              27,
                                                              "Success!"
                                                            ),
                                                            "ok-variant":
                                                              "success",
                                                            "ok-only": "",
                                                            "modal-class":
                                                              "mui-animation",
                                                            fade: false,
                                                          },
                                                          on: {
                                                            ok: function (
                                                              $event
                                                            ) {
                                                              _vm.successModal = false
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.successModal,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.successModal =
                                                                $$v
                                                            },
                                                            expression:
                                                              "successModal",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  28,
                                                                  "The new document has been saved."
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-modal",
                                                        {
                                                          staticClass:
                                                            "modal-success",
                                                          attrs: {
                                                            title: _vm.FormMSG(
                                                              27,
                                                              "Success!"
                                                            ),
                                                            "ok-variant":
                                                              "success",
                                                            "ok-only": "",
                                                            "modal-class":
                                                              "mui-animation",
                                                            fade: false,
                                                          },
                                                          on: {
                                                            ok: function (
                                                              $event
                                                            ) {
                                                              _vm.successModalDelete = false
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.successModalDelete,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.successModalDelete =
                                                                $$v
                                                            },
                                                            expression:
                                                              "successModalDelete",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  29,
                                                                  "Your document has been deleted."
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-card",
                                                        {
                                                          staticClass:
                                                            "card-border-blue-light",
                                                          attrs: {
                                                            "no-body": "",
                                                          },
                                                        },
                                                        [
                                                          _c("b-card-header", [
                                                            _c("h4", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.caption2
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c(
                                                            "b-card-body",
                                                            [
                                                              _vm.newDoc
                                                                .type !== 11
                                                                ? _c(
                                                                    "b-row",
                                                                    {
                                                                      staticClass:
                                                                        "pb-3",
                                                                    },
                                                                    [
                                                                      !_vm.isDocumentPackage
                                                                        ? _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  sm: "12",
                                                                                  md: "6",
                                                                                  lg: "6",
                                                                                  xl: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .$screen
                                                                                .width >=
                                                                              992
                                                                                ? _c(
                                                                                    "b-input-group",
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "text",
                                                                                              placeholder:
                                                                                                _vm.FormMSG(
                                                                                                  31,
                                                                                                  "Type to Search"
                                                                                                ),
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.filter,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.filter =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "filter",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "b-input-group-append",
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-input-group-text",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn-search",
                                                                                            },
                                                                                            [
                                                                                              _vm
                                                                                                .filter
                                                                                                .length ===
                                                                                              0
                                                                                                ? _c(
                                                                                                    _vm.getIconLucide(
                                                                                                      "Search"
                                                                                                    ),
                                                                                                    {
                                                                                                      tag: "component",
                                                                                                      staticClass:
                                                                                                        "icon",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "#FFFFFF",
                                                                                                          size: 16,
                                                                                                          "stroke-width": 2.5,
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _c(
                                                                                                    _vm.getIconLucide(
                                                                                                      "X"
                                                                                                    ),
                                                                                                    {
                                                                                                      tag: "component",
                                                                                                      staticClass:
                                                                                                        "icon",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "#FFFFFF",
                                                                                                          size: 16,
                                                                                                          "stroke-width": 2.5,
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.filter =
                                                                                                              ""
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "12",
                                                                              md: "6",
                                                                              lg: "6",
                                                                              xl: "6",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "float-right",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "success",
                                                                                      disabled:
                                                                                        _vm
                                                                                          .newDoc
                                                                                          .xid ===
                                                                                        0,
                                                                                      size: "md",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.showDeliveries()
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        this.FormMSG(
                                                                                          219,
                                                                                          "Select receivers..."
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                        xl: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? _c(
                                                                            "CardListBuilder",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  items:
                                                                                    _vm.Dlvs,
                                                                                  fields:
                                                                                    _vm.delifields,
                                                                                },
                                                                              on: {
                                                                                "row-clicked":
                                                                                  _vm.rowClicked,
                                                                              },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "actions",
                                                                                      fn: function (
                                                                                        data
                                                                                      ) {
                                                                                        return [
                                                                                          data
                                                                                            .item
                                                                                            .selected &&
                                                                                          data
                                                                                            .item
                                                                                            .status >
                                                                                            0 &&
                                                                                          _vm
                                                                                            .newDoc
                                                                                            .fileName &&
                                                                                          _vm
                                                                                            .newDoc
                                                                                            .fileName
                                                                                            .length >
                                                                                            0
                                                                                            ? _c(
                                                                                                "b-button",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      variant:
                                                                                                        "primary",
                                                                                                      size: "sm",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.viewDocument(
                                                                                                          data.item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "i",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "icon-eye",
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          data
                                                                                            .item
                                                                                            .senderStatus ===
                                                                                            2 &&
                                                                                          _vm
                                                                                            .newDoc
                                                                                            .toSign
                                                                                            ? _c(
                                                                                                "b-button",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      variant:
                                                                                                        "primary",
                                                                                                      size: "sm",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.viewDocument(
                                                                                                          data.item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "i",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "icon-eye",
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  false,
                                                                                  996730115
                                                                                ),
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .$screen
                                                                        .width >=
                                                                        992 &&
                                                                      _vm.newDoc
                                                                        .type !==
                                                                        11
                                                                        ? _c(
                                                                            "b-table",
                                                                            {
                                                                              ref: "myTable",
                                                                              attrs:
                                                                                {
                                                                                  hover:
                                                                                    _vm.hover,
                                                                                  responsive:
                                                                                    "sm",
                                                                                  "sticky-header":
                                                                                    "600px",
                                                                                  selectable:
                                                                                    "",
                                                                                  selectedVariant:
                                                                                    _vm.selectedColor,
                                                                                  "select-mode":
                                                                                    _vm.selectMode,
                                                                                  items:
                                                                                    _vm.Dlvs,
                                                                                  fields:
                                                                                    _vm.delifields,
                                                                                  "current-page":
                                                                                    _vm.currentPage,
                                                                                  filter:
                                                                                    _vm.filter,
                                                                                  "per-page":
                                                                                    _vm.perPage,
                                                                                  bordered:
                                                                                    "",
                                                                                  small:
                                                                                    "",
                                                                                  "head-variant":
                                                                                    _vm.hv,
                                                                                },
                                                                              on: {
                                                                                "row-clicked":
                                                                                  _vm.rowClicked,
                                                                              },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "cell(status)",
                                                                                      fn: function (
                                                                                        data
                                                                                      ) {
                                                                                        return [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "wrap-status",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  class: `status ${data.item.validatedClass}`,
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "0.7rem",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                      _vm._s(
                                                                                                        data.value
                                                                                                      ) +
                                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      key: "cell(senderStatus)",
                                                                                      fn: function (
                                                                                        data
                                                                                      ) {
                                                                                        return [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "wrap-status",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  class: `status ${data.item.myValidatedClass}`,
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "font-size":
                                                                                                        "0.7rem",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                      _vm._s(
                                                                                                        data.value
                                                                                                      ) +
                                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      key: "cell(view)",
                                                                                      fn: function (
                                                                                        data
                                                                                      ) {
                                                                                        return [
                                                                                          data
                                                                                            .item
                                                                                            .status >
                                                                                          0
                                                                                            ? _c(
                                                                                                _vm.getIconLucide(
                                                                                                  "Eye"
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      color:
                                                                                                        "#06263E",
                                                                                                      size: 16,
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.viewDocument(
                                                                                                          data.item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  false,
                                                                                  3182386789
                                                                                ),
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .$screen
                                                                        .width >=
                                                                        992 &&
                                                                      _vm.newDoc
                                                                        .type ===
                                                                        11
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "table-hierarchical-document-delivery",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "document-package-id":
                                                                                        _vm
                                                                                          .newDoc
                                                                                          .id,
                                                                                      "current-document":
                                                                                        _vm.newDoc,
                                                                                      "show-select-receiver": true,
                                                                                    },
                                                                                  on: {
                                                                                    "table-hierarchical-document-delivery:sign":
                                                                                      _vm.handleTableHierarchicalDocumentDeliverySign,
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-modal",
                                                                {
                                                                  staticClass:
                                                                    "modal-success",
                                                                  attrs: {
                                                                    title:
                                                                      this.FormMSG(
                                                                        120,
                                                                        "Success!"
                                                                      ),
                                                                    "ok-variant":
                                                                      "success",
                                                                    "ok-only":
                                                                      "",
                                                                    "modal-class":
                                                                      "mui-animation",
                                                                    fade: false,
                                                                  },
                                                                  on: {
                                                                    ok: function (
                                                                      $event
                                                                    ) {
                                                                      _vm.successSignModal = false
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.successSignModal,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.successSignModal =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "successSignModal",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          122,
                                                                          "The document has been signed."
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("loading", {
                                                                attrs: {
                                                                  active:
                                                                    _vm.isLoading,
                                                                  "is-full-page": true,
                                                                },
                                                                on: {
                                                                  "update:active":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.isLoading =
                                                                        $event
                                                                    },
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-success",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(253, "Picture"),
                  "ok-variant": "success",
                  "ok-only": "",
                  "modal-class": "mui-animation",
                  fade: false,
                },
                on: {
                  ok: function ($event) {
                    _vm.openPictureModal = false
                  },
                },
                model: {
                  value: _vm.openPictureModal,
                  callback: function ($$v) {
                    _vm.openPictureModal = $$v
                  },
                  expression: "openPictureModal",
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("b-img", {
                      attrs: { src: _vm.imgToShow, "fluid-grow": "" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }