<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				class="container-layout details-document-package"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<!-- <pre>{{ roleByStepToSend }}</pre> -->
				<div class="form">
					<b-row>
						<b-col md="12" class="px-0">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(55, 'Document Details') }}</h1>
						</b-col>
					</b-row>
					<b-row class="mt-0">
						<b-col class="p-0">
							<b-card no-body>
								<b-row class="back-with-title cursor-pointer pt-0 pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
									<b-col cols="6">
										<button class="btn-transparent" @click="handleClickGoBack">
											<component :is="getIconLucide('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
											<span class="title-back-bolder mx-0">
												{{ FormMSG(163, 'Back') }}
											</span>
										</button>
									</b-col>
								</b-row>
								<b-card-text class="pt-4">
									<div class="px-1">
										<b-row v-if="Docs">
											<b-col cols="12" xl="12">
												<div v-if="newDoc">
													<div class="card card-border-blue-light p-3">
														<b-row>
															<b-col cols="6">
																<b-form-group :label="FormMSG(10, 'Object')" label-for="docName" label-cols="3">
																	<b-form-input
																		v-model="newDoc.name"
																		type="text"
																		id="docName"
																		:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																	/>
																</b-form-group>
																<b-form-group :label="FormMSG(11, 'Type')" label-for="docType" label-cols="3">
																	<b-form-select
																		v-if="!isDocumentPackage"
																		v-model="newDoc.type"
																		:options="optionsForDocType"
																		size="md"
																		id="docType"
																		:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																		@change="handleChangeType"
																	/>
																	<b-form-input v-else :value="FormMSG(421, 'Onboarding package')" disabled />
																</b-form-group>
																<b-row v-if="!isDocumentPackage">
																	<b-col>
																		<b-form-group
																			v-if="newDoc.type === 9"
																			:label="FormMSG(13, 'File')"
																			label-for="docFileBrowse"
																			label-cols="3"
																		>
																			<b-form-file
																				v-model="pdfFile"
																				:browse-text="FormMSG(113, 'Browse')"
																				name="test"
																				id="docFileBrowse"
																				accept=".docx,.pdf"
																				ref="pdfFileId"
																				@change="submitDocFile"
																				:placeholder="FormMSG(156, 'Choose a file or drop it here...')"
																				:drop-placeholder="FormMSG(157, 'Drop file here...')"
																				:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			/>
																		</b-form-group>
																		<b-form-group
																			v-if="newDoc.type != 9"
																			:label="FormMSG(13, 'File')"
																			label-for="docFileBrowse"
																			label-cols="3"
																		>
																			<b-form-file
																				v-model="pdfFile"
																				:browse-text="FormMSG(113, 'Browse')"
																				name="test"
																				id="docFileBrowse"
																				accept=".jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt"
																				ref="pdfFileId"
																				@change="submitDocFile"
																				:placeholder="FormMSG(156, 'Choose a file or drop it here...')"
																				:drop-placeholder="FormMSG(157, 'Drop file here...')"
																				:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			/>
																		</b-form-group>
																	</b-col>
																</b-row>
																<b-row v-show="!isDocumentPackage">
																	<b-col cols="7" offset="3" class="d-flex justify-content-start align-items-end">
																		<b-form-group label="" class="w-100">
																			<b-form-input
																				:value="newDoc.fileName"
																				:placeholder="FormMSG(58, 'File name...')"
																				disabled
																				style="margin-left: -4px"
																			/>
																		</b-form-group>
																	</b-col>
																	<b-col
																		v-if="newDoc.fileName && newDoc.fileName.length > 0"
																		cols="2"
																		class="d-flex justify-content-start align-items-center"
																		style="margin-top: -12px"
																	>
																		<button
																			:class="{
																				'btn-transparent text-color-burning-tomato': $screen.width > 576,
																				'btn btn-danger mr-2': $screen.width <= 576
																			}"
																			:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			@click="removePdfFile"
																		>
																			<component :is="getIconLucide('Trash2')" :size="22" :stroke-width="1.5" />
																		</button>
																		<button
																			:class="{
																				'btn-transparent text-color-rhapsody-in-blue': $screen.width > 576,
																				'btn btn-primary': $screen.width <= 576
																			}"
																			@click="handlePreviewDoc"
																		>
																			<component :is="getIconLucide('Eye')" :size="22" :stroke-width="1.5" />
																		</button>
																	</b-col>
																</b-row>
															</b-col>
															<b-col cols="6">
																<b-row>
																	<b-col cols="6">
																		<b-form-checkbox
																			size="lg"
																			id="toApp"
																			v-model="newDoc.availableOnline"
																			:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			class="pj-cb pb-1"
																		>
																			{{ FormMSG(14, 'View in App') }}
																		</b-form-checkbox>
																	</b-col>
																	<b-col cols="6">
																		<b-form-checkbox
																			size="lg"
																			id="toEmail"
																			v-model="newDoc.toEmail"
																			:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			class="pj-cb pb-1"
																		>
																			{{ FormMSG(15, 'Send by email') }}
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-row>
																	<b-col cols="6">
																		<b-form-checkbox
																			size="lg"
																			id="toSms"
																			v-model="newDoc.toSms"
																			:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			class="pj-cb pb-1"
																		>
																			{{ FormMSG(41, 'Send SMS') }}
																		</b-form-checkbox>
																	</b-col>
																	<b-col cols="6">
																		<b-form-checkbox
																			size="lg"
																			id="toWhatsApp"
																			v-model="newDoc.toWhatsApp"
																			:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																			class="pj-cb pb-1"
																		>
																			{{ FormMSG(42, 'Send WhatsApp') }}
																		</b-form-checkbox>
																	</b-col>
																</b-row>
																<b-form-group :label-cols="0" class="mt-2">
																	<b-form-textarea
																		id="body"
																		:placeholder="FormMSG(16, 'Enter message here...')"
																		:no-resize="true"
																		:state="newDoc.body && newDoc.body.length < 4000"
																		v-model="newDoc.body"
																		rows="5"
																		:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
														<div>
															<div class="divider" />
															<div class="sub-title mb-2">{{ FormMSG(168, 'Options') }}</div>
															<b-row>
																<b-col v-show="!isDocumentPackage">
																	<b-form-checkbox
																		size="lg"
																		id="toSign"
																		v-model="newDoc.toSign"
																		:disabled="
																			isDocumentPackage ||
																			disableByStatus(newDoc.status) ||
																			disableToSignByStep ||
																			newDoc.requireManagerApproval
																		"
																		class="pj-cb pb-1"
																		@change="handleChangeToSign"
																	>
																		{{ FormMSG(315, 'To sign by user') }}
																	</b-form-checkbox>
																</b-col>
																<b-col>
																	<b-form-checkbox
																		size="lg"
																		id="requireSignManager"
																		v-model="newDoc.requireManagerApproval"
																		:disabled="
																			isDocumentPackage || disableSelectRequireApproval || disableByStatus(newDoc.status)
																		"
																		class="pj-cb pb-1"
																		@change="handleChangeManagerApproval"
																	>
																		{{ FormMSG(169, 'Require approval / manager signature') }}
																	</b-form-checkbox>
																</b-col>
															</b-row>
															<b-collapse :visible="newDoc.requireManagerApproval">
																<b-row class="mt-3" v-if="newDoc.requireManagerApproval">
																	<b-col>
																		<fieldset class="card-inside">
																			<legend class="card-inside">
																				{{ FormMSG(152, 'Approval steps') }}
																			</legend>
																			<div class="container-field">
																				<choice-step-document
																					:is-disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																					:document="newDoc"
																					@choice-step-document:change="handleChoiceStepDocumentChange"
																				/>
																			</div>
																		</fieldset>
																	</b-col>
																</b-row>
															</b-collapse>
														</div>
														<div v-if="isPdfFile()">
															<div class="divider" />
															<div class="sub-title mb-2">{{ FormMSG(167, 'Watermark options') }}</div>
															<b-row class="pb-2">
																<b-col sm="12" md="12" lg="12" xl="12">
																	<b-form-checkbox
																		size="lg"
																		id="watermark"
																		v-model="newDoc.useWatermark"
																		:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																		class="pj-cb pb-1"
																	>
																		{{ FormMSG(21, 'Add Watermark') }}
																	</b-form-checkbox>
																</b-col>
															</b-row>
															<b-collapse :visible="newDoc.useWatermark">
																<div class="container-field">
																	<b-row v-if="isPdfFile() && newDoc.useWatermark">
																		<b-col sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(22, 'Watermark text')" label-for="watermarkText">
																				<b-form-input
																					v-model="newDoc.watermarkText"
																					type="text"
																					id="watermarkText"
																					:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																				/>
																			</b-form-group>
																		</b-col>
																		<b-col
																			v-if="newDoc.useWatermark"
																			sm="12"
																			md="6"
																			lg="6"
																			xl="6"
																			class="d-flex justify-content-start align-items-end"
																			style="margin-bottom: 12px"
																		>
																			<b-form-checkbox
																				size="lg"
																				id="withusername"
																				v-model="newDoc.watermarkWithUser"
																				:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																				class="pj-cb pb-1"
																			>
																				{{ FormMSG(23, 'With user name') }}
																			</b-form-checkbox>
																		</b-col>
																	</b-row>
																	<b-row v-if="isPdfFile() && newDoc.useWatermark" class="pb-1">
																		<b-col sm="12" md="6" lg="6" xl="6">
																			<b-form-group :label="FormMSG(24, 'Watermark image')" label-for="watermarkImage">
																				<b-form-file
																					v-model="wmImageFile"
																					:browse-text="FormMSG(113, 'Browse')"
																					accept=".jpg,.png,.gif"
																					id="watermarkImage"
																					@change="submitWmFile"
																					ref="wmFileFieldId"
																					:placeholder="FormMSG(212, 'Choose watermark image or drop it here...')"
																					:drop-placeholder="FormMSG(213, 'Drop file here...')"
																					:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																				/>
																			</b-form-group>
																		</b-col>
																		<b-col
																			:sm="`${
																				newDoc.watermarkPictureFileName && newDoc.watermarkPictureFileName.length > 0
																					? 10
																					: 12
																			}`"
																			:md="`${
																				newDoc.watermarkPictureFileName && newDoc.watermarkPictureFileName.length > 0
																					? 4
																					: 6
																			}`"
																			:lg="`${
																				newDoc.watermarkPictureFileName && newDoc.watermarkPictureFileName.length > 0
																					? 4
																					: 6
																			}`"
																			:xl="`${
																				newDoc.watermarkPictureFileName && newDoc.watermarkPictureFileName.length > 0
																					? 4
																					: 6
																			}`"
																			class="d-flex justify-content-start align-items-end"
																			style="margin-bottom: -3px"
																		>
																			<b-form-group label="" class="w-100">
																				<b-form-input
																					:value="newDoc.watermarkPictureFileName"
																					:placeholder="FormMSG(58, 'File name...')"
																					disabled
																				/>
																			</b-form-group>
																		</b-col>
																		<b-col
																			v-if="newDoc.watermarkPictureFileName && newDoc.watermarkPictureFileName.length > 0"
																			sm="2"
																			md="2"
																			lg="2"
																			xl="2"
																			class="d-flex justify-content-start align-items-center"
																			style="margin-bottom: -16px"
																		>
																			<button
																				:class="{
																					'btn-transparent text-color-burning-tomato': $screen.width > 576,
																					'btn btn-danger mr-2': $screen.width <= 576
																				}"
																				:disabled="isDocumentPackage || disableByStatus(newDoc.status)"
																				@click="removeWmFile"
																			>
																				<component :is="getIconLucide('Trash2')" :size="22" :stroke-width="1.5" />
																			</button>
																			<button
																				:class="{
																					'btn-transparent text-color-rhapsody-in-blue': $screen.width > 576,
																					'btn btn-primary': $screen.width <= 576
																				}"
																				@click="handlePreviewWm"
																			>
																				<component :is="getIconLucide('Eye')" :size="22" :stroke-width="1.5" />
																			</button>
																		</b-col>
																	</b-row>
																</div>
															</b-collapse>
														</div>
														<b-collapse :visible="showPasswordOptions">
															<div class="divider" />
															<div class="sub-title mb-2">
																{{ FormMSG(198, 'Password options') }}
															</div>
															<div>
																<div class="container-field">
																	<b-row>
																		<b-col cols="6">
																			<b-form-group :label="FormMSG(25, 'Pdf User password')" label-for="UserPwd">
																				<b-input-group class="mb-4">
																					<b-form-input
																						v-model="newDoc.userPassword"
																						autocomplete="nope"
																						:type="!showTextConfirmPassword ? 'password' : 'text'"
																						id="UserPwd"
																						:placeholder="
																							FormMSG(214, 'If protected, enter document user password .')
																						"
																						:disabled="isDocumentPackage"
																					/>
																					<b-input-group-append>
																						<button
																							class="btn-show-password"
																							type="button"
																							@click="showTextConfirmPassword = !showTextConfirmPassword"
																						>
																							<component
																								v-if="!showTextConfirmPassword"
																								:is="getIconLucide('Eye')"
																								color="#9c9d9e"
																								:size="16"
																							/>
																							<component
																								:is="getIconLucide('EyeOff')"
																								v-if="showTextConfirmPassword"
																								color="#9c9d9e"
																								:size="16"
																							/>
																						</button>
																					</b-input-group-append>
																				</b-input-group>
																			</b-form-group>
																		</b-col>
																		<b-col cols="6">
																			<b-form-group :label="FormMSG(26, 'Pdf Owner password')" label-for="OwnerPwd">
																				<b-input-group class="mb-4">
																					<b-form-input
																						v-model="newDoc.ownerPassword"
																						:type="!showTextPassword ? 'password' : 'text'"
																						id="OwnerPwd"
																						:placeholder="FormMSG(216, 'Enter document owner password.')"
																						:disabled="isDocumentPackage"
																					/>
																					<b-input-group-append>
																						<button
																							class="btn-show-password"
																							type="button"
																							@click="showTextPassword = !showTextPassword"
																						>
																							<component
																								v-if="!showTextPassword"
																								:is="getIconLucide('Eye')"
																								color="#9c9d9e"
																								:size="16"
																							/>
																							<component
																								:is="getIconLucide('EyeOff')"
																								v-if="showTextPassword"
																								color="#9c9d9e"
																								:size="16"
																							/>
																						</button>
																					</b-input-group-append>
																				</b-input-group>
																			</b-form-group>
																		</b-col>
																	</b-row>
																</div>
															</div>
														</b-collapse>
														<b-row v-if="!isDocumentPackage || !disableByStatus(newDoc.status)" class="pt-3">
															<b-col sm="12" md="4" lg="4" xl="4" class="d-flex justify-content-center align-items-center">
																<b-form-group>
																	<h6>{{ this.lastPublishedOn }}</h6>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="4" lg="4" xl="4">
																<b-form-group v-if="newDoc.type === 9">
																	<b-button @click="downloadPDF()" variant="success" size="md" class="btn btn-primary" block>
																		{{ this.FormMSG(20, 'Download mail merge fields...') }}
																	</b-button>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="4" lg="4" xl="4">
																<b-form-group>
																	<b-button
																		@click="curDocUpd()"
																		variant="primary"
																		size="md"
																		:disabled="disableSave"
																		class="btn btn-primary btn-md float-right"
																		block
																	>
																		{{ this.FormMSG(18, 'Save modifications') }}
																	</b-button>
																</b-form-group>
															</b-col>
														</b-row>
													</div>
													<b-modal
														:title="FormMSG(27, 'Success!')"
														class="modal-success"
														v-model="successModal"
														@ok="successModal = false"
														ok-variant="success"
														ok-only
														modal-class="mui-animation"
														:fade="false"
													>
														{{ this.FormMSG(28, 'The new document has been saved.') }}
													</b-modal>
													<b-modal
														:title="FormMSG(27, 'Success!')"
														class="modal-success"
														v-model="successModalDelete"
														@ok="successModalDelete = false"
														ok-variant="success"
														ok-only
														modal-class="mui-animation"
														:fade="false"
													>
														{{ this.FormMSG(29, 'Your document has been deleted.') }}
													</b-modal>
													<b-card no-body class="card-border-blue-light">
														<b-card-header>
															<h4>{{ this.caption2 }}</h4>
														</b-card-header>
														<b-card-body>
															<b-row v-if="newDoc.type !== 11" class="pb-3">
																<b-col sm="12" md="6" lg="6" xl="6" v-if="!isDocumentPackage">
																	<b-input-group v-if="$screen.width >= 992">
																		<b-form-input
																			v-model="filter"
																			type="text"
																			:placeholder="FormMSG(31, 'Type to Search')"
																		/>
																		<b-input-group-append class="cursor-pointer">
																			<b-input-group-text class="btn-search">
																				<component
																					:is="getIconLucide('Search')"
																					color="#FFFFFF"
																					:size="16"
																					class="icon"
																					:stroke-width="2.5"
																					v-if="filter.length === 0"
																				/>
																				<component
																					:is="getIconLucide('X')"
																					color="#FFFFFF"
																					:size="16"
																					class="icon"
																					:stroke-width="2.5"
																					@click="filter = ''"
																					v-else
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-col>
																<b-col sm="12" md="6" lg="6" xl="6">
																	<div class="float-right">
																		<b-button
																			variant="success"
																			:disabled="newDoc.xid === 0"
																			size="md"
																			@click="showDeliveries()"
																		>
																			{{ this.FormMSG(219, 'Select receivers...') }}
																		</b-button>
																	</div>
																</b-col>
															</b-row>
															<!-- TABLE OF Departments use striped to highlight each even row   @row-selected="rowSelected"  :disabled="data.item.department!=0 && data.item.type==0" -->
															<b-row>
																<b-col cols="12" xl="12">
																	<CardListBuilder
																		v-if="$screen.width < 992"
																		:items="Dlvs"
																		:fields="delifields"
																		@row-clicked="rowClicked"
																	>
																		<template slot="actions" slot-scope="data">
																			<b-button
																				variant="primary"
																				v-if="
																					data.item.selected &&
																					data.item.status > 0 &&
																					newDoc.fileName &&
																					newDoc.fileName.length > 0
																				"
																				size="sm"
																				@click="viewDocument(data.item)"
																			>
																				<i class="icon-eye"></i>
																			</b-button>
																			<b-button
																				variant="primary"
																				v-if="data.item.senderStatus === 2 && newDoc.toSign"
																				size="sm"
																				@click="viewDocument(data.item)"
																			>
																				<i class="icon-eye"></i>
																			</b-button>
																			<!-- <b-button
																				variant="primary"
																				v-if="
																					data.item.senderStatus > 2 &&
																					newDoc.fileName &&
																					newDoc.fileName.length > 0 &&
																					newDoc.toSign
																				"
																				size="sm"
																				@click="viewDocumentCertificate(data.item)"
																			>
																				<i class="icon-cloud-download"></i>
																			</b-button> -->
																		</template>
																	</CardListBuilder>
																	<b-table
																		v-if="$screen.width >= 992 && newDoc.type !== 11"
																		:hover="hover"
																		responsive="sm"
																		sticky-header="600px"
																		ref="myTable"
																		selectable
																		:selectedVariant="selectedColor"
																		:select-mode="selectMode"
																		:items="Dlvs"
																		:fields="delifields"
																		:current-page="currentPage"
																		:filter="filter"
																		:per-page="perPage"
																		@row-clicked="rowClicked"
																		bordered
																		small
																		:head-variant="hv"
																	>
																		<template #cell(status)="data">
																			<div class="wrap-status">
																				<div :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
																					{{ data.value }}
																				</div>
																			</div>
																		</template>
																		<template #cell(senderStatus)="data">
																			<div class="wrap-status">
																				<div :class="`status ${data.item.myValidatedClass}`" style="font-size: 0.7rem">
																					{{ data.value }}
																				</div>
																			</div>
																		</template>
																		<template v-slot:cell(view)="data">
																			<component
																				:is="getIconLucide('Eye')"
																				color="#06263E"
																				:size="16"
																				v-if="data.item.status > 0"
																				@click="viewDocument(data.item)"
																			/>
																		</template>
																		<!-- <template v-slot:cell(certificate)="data">
																			<component
																				:is="getIconLucide('DownloadCloud')"
																				color="#06263E"
																				:size="16"
																				v-if="
																					data.item.senderStatus > 2 &&
																					newDoc.fileName &&
																					newDoc.fileName.length > 0 &&
																					newDoc.toSign
																				"
																				@click="downloadCertificate(data.item)"
																			/>
																		</template> -->
																	</b-table>
																	<div v-if="$screen.width >= 992 && newDoc.type === 11">
																		<table-hierarchical-document-delivery
																			:document-package-id="newDoc.id"
																			:current-document="newDoc"
																			:show-select-receiver="true"
																			@table-hierarchical-document-delivery:sign="
																				handleTableHierarchicalDocumentDeliverySign
																			"
																		/>
																	</div>
																</b-col>
															</b-row>
															<b-modal
																:title="this.FormMSG(120, 'Success!')"
																class="modal-success"
																v-model="successSignModal"
																@ok="successSignModal = false"
																ok-variant="success"
																ok-only
																modal-class="mui-animation"
																:fade="false"
															>
																{{ FormMSG(122, 'The document has been signed.') }}
															</b-modal>
															<loading :active.sync="isLoading" :is-full-page="true"></loading>
														</b-card-body>
													</b-card>
												</div>
											</b-col>
										</b-row>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
				<b-modal
					header-class="header-class-modal-doc-package"
					v-model="openPictureModal"
					:title="FormMSG(253, 'Picture')"
					class="modal-success"
					ok-variant="success"
					ok-only
					@ok="openPictureModal = false"
					modal-class="mui-animation"
					:fade="false"
				>
					<div>
						<b-img :src="imgToShow" fluid-grow />
					</div>
				</b-modal>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { isNil } from '@/shared/utils';
import { addUpdDocValConfig, deleteDocument, getDocument, getDocumentList, updateDocument } from '@/modules/document-package/cruds/document.crud';
import { classDocumentStatus } from '@/shared/global-status';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import * as icons from 'lucide-vue';
import TableHierarchicalDocumentDelivery from '@/modules/document-package/components/TableHierarchicalDocumentDelivery';
import ChoiceStepDocument from '@/modules/document-package/components/ChoiceStepDocument';
import moment from 'moment';
import { getDocumentDeliveries } from '@/cruds/document.crud';
import _ from 'lodash';
import { DOCUMENT_VALIDATION_STEP, DOCUMENT_VALIDATION_LEVEL } from '@/shared/constants';

const axios = require('axios').create();

export default {
	name: 'DocumentDetails',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		TableHierarchicalDocumentDelivery,
		ChoiceStepDocument
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			formDataObj: {},
			Docs: [],
			Dlvs: [],
			curDoc: {},
			newDoc: {},
			currentPage: 1,
			currentIdx: 0,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			pdfFile: null,
			wmImageFile: null,
			displayAllCategories: false,
			stopPropagation: '',
			nativeEvent: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			firstTime: true,
			lastPublishedOn: '',
			successSignModal: false,
			checkPasswordModal: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			passwordInput: '',
			userIdSelected: 0,
			name: '',
			nameState: null,
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false,
			selectedItem: null,
			isDocumentPackage: false,
			openPictureModal: false,
			imgToShow: '',
			requireSignManager: false,
			roleByStepToSend: [],
			disableSelectRequireApproval: true,
			showTextPassword: false,
			showTextConfirmPassword: false,
			managerToSignChanged: false,
			isChangedGateKeeper: false,
			isChangedProductionAccountant: false,
			isChangedUpm: false,
			isChangedLineProducer: false,
			isChangedStudio: false
		};
	},
	computed: {
		disableToSignByStep() {
			return (
				this.roleByStepToSend.length > 0 ||
				this.newDoc.docValConfigStep1.length > 0 ||
				this.newDoc.docValConfigStep2.length > 0 ||
				this.newDoc.docValConfigStep3.length > 0
			);
		},
		showPasswordOptions() {
			if (this.isPdfFile()) {
				if (this.newDoc.useWatermark || this.newDoc.toSign) {
					return true;
				}
			}
			return false;
		},
		optionsForDocType() {
			let result = this.FormMenu(1011);

			result = result.filter((res) => res.value !== 11);
			return result;
		},
		delifields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(50, 'Name'),
					sortable: true
				},
				{
					key: 'user.departmentName',
					label: this.FormMSG(51, 'Department'),
					sortable: true
				},
				{
					key: 'user.functionName',
					label: this.FormMSG(52, 'Function'),
					sortable: true
				},
				{
					key: 'contractStartDate',
					label: this.FormMSG(118, 'Contract start date'),
					formatter: (v) => moment(v).format('DD/MM/YYYY'),
					sortable: true
				},
				{
					key: 'contractEndDate',
					label: this.FormMSG(19, 'Contract end date'),
					formatter: (v) => {
						const value = v.split('T')[0];
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'status',
					label: this.FormMSG(43, 'User Status'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1012, value); // 0 not published, 1 not received, 2 received, 3 read, 4 signed
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'senderStatus',
					label: this.FormMSG(46, 'Production Status'),
					formatter: (value, key, item) => {
						return this.getLabelStep(item.status, parseInt(value), item.currentStep, this.curDoc.requireManagerApproval); // 0 not published, 1 not received, 2 received, 3 read, 4 signed
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'view',
					label: this.FormMSG(44, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
				// {
				// 	key: 'certificate',
				// 	label: this.FormMSG(48, 'Certificate'),
				// 	sortable: false,
				// 	formatter: (v) => v,
				// 	class: 'text-center'
				// }
			];
		},
		caption1() {
			return this.FormMSG(101, 'Document details');
		},
		caption2() {
			return this.FormMSG(102, 'Document shared with...');
		},
		disableSave() {
			if (
				this.newDoc.name !== this.curDoc.name ||
				this.newDoc.type !== this.curDoc.type ||
				this.newDoc.availableOnline !== this.curDoc.availableOnline ||
				this.newDoc.toEmail !== this.curDoc.toEmail ||
				this.newDoc.toSms !== this.curDoc.toSms ||
				this.newDoc.toWhatsApp !== this.curDoc.toWhatsApp ||
				this.newDoc.toSign !== this.curDoc.toSign ||
				this.newDoc.watermarkWithUser !== this.curDoc.watermarkWithUser ||
				this.newDoc.watermarkText !== this.curDoc.watermarkText ||
				this.newDoc.useWatermark !== this.curDoc.useWatermark ||
				this.newDoc.body !== this.curDoc.body ||
				this.newDoc.userPassword !== this.curDoc.userPassword ||
				this.newDoc.ownerPassword !== this.curDoc.ownerPassword
			) {
				if (this.newDoc.requireManagerApproval !== this.curDoc.requireManagerApproval && this.roleByStepToSend.length === 0) {
					return true;
				}

				if (this.roleByStepToSend.length > 0) {
					return this.checkToSignWithUserIdZero();
				}

				return false;
			} else {
				if (this.newDoc.requireManagerApproval !== this.curDoc.requireManagerApproval && this.roleByStepToSend.length === 0) {
					return true;
				}

				if (this.roleByStepToSend.length > 0) {
					return this.checkToSignWithUserIdZero();
				}
			}

			return true;
		}
	},
	methods: {
		checkToSignWithUserIdZero() {
			let cannotSave = false;
			for (const obj of this.roleByStepToSend) {
				if (obj.action === 0) {
					if ((obj.toSign && obj.userId === 0) || (!obj.userId && obj.toSign)) {
						cannotSave = true;
					} else {
						cannotSave = false;
					}
				} else {
					cannotSave = false;
				}
			}

			return cannotSave; // No matching object found, return false
		},
		disableByStatus(actualStatus) {
			const statuesToCheck = [0, 1, 2];
			if (statuesToCheck.includes(parseInt(actualStatus, 10))) {
				return false;
			}

			return true;
		},
		handleChangeType(payload) {
			if (payload === 10) {
				this.disableSelectRequireApproval = false;
			}
		},
		handleChangeToSign(payload) {
			if (!payload) {
				this.disableSelectRequireApproval = true;
			} else {
				this.disableSelectRequireApproval = false;
			}
		},
		handleChoiceStepDocumentChange(payload) {
			this.roleByStepToSend = payload;
			if (payload.length > 0) {
				const indexZeroUserId = payload.findIndex((step) => step.userId === 0);
				if (indexZeroUserId > -1) {
					this.managerToSignChanged = true;
				} else {
					if (
						this.newDoc.docValConfigStep1.length === 0 &&
						this.newDoc.docValConfigStep2.length === 0 &&
						this.newDoc.docValConfigStep3.length === 0
					) {
						this.managerToSignChanged = false;
					} else {
						for (const validationStep of payload) {
							let step = null;
							if (validationStep.step === DOCUMENT_VALIDATION_STEP.step1) {
								if (this.newDoc.docValConfigStep1.length > 0) {
									step = this.newDoc.docValConfigStep1.filter((item) => item.userId === validationStep.userId);
									if (step.length > 0) {
										this.isChangedGateKeeper = true;
									} else {
										this.isChangedGateKeeper = false;
									}
								} else {
									this.isChangedGateKeeper = false;
								}
							} else if (validationStep.step === DOCUMENT_VALIDATION_STEP.step2) {
								if (this.newDoc.docValConfigStep2.length > 0) {
									step = this.newDoc.docValConfigStep2.filter((item) => item.userId === validationStep.userId);
									if (step.length > 0) {
										this.isChangedProductionAccountant = true;
									} else {
										this.isChangedProductionAccountant = false;
									}
								} else {
									this.isChangedProductionAccountant = false;
								}
							} else if (validationStep.step === DOCUMENT_VALIDATION_STEP.step3) {
								if (this.newDoc.docValConfigStep3.length > 0) {
									step = this.newDoc.docValConfigStep2.filter((item) => item.userId === validationStep.userId);

									if (validationStep.validationLevel === DOCUMENT_VALIDATION_LEVEL.upm) {
										if (step.length > 0) {
											this.isChangedUpm = true;
										} else {
											this.isChangedUpm = false;
										}
									} else if (validationStep.validationLevel === DOCUMENT_VALIDATION_LEVEL.lineProducer) {
										if (step.length > 0) {
											this.isChangedLineProducer = true;
										} else {
											this.isChangedLineProducer = false;
										}
									} else if (validationStep.validationLevel === DOCUMENT_VALIDATION_LEVEL.studio) {
										if (step.length > 0) {
											this.isChangedStudio = true;
										} else {
											this.isChangedStudio = false;
										}
									}
								} else {
									this.isChangedUpm = false;
									this.isChangedLineProducer = false;
									this.isChangedStudio = false;
								}
							}
						}
						this.managerToSignChanged =
							this.isChangedGateKeeper &&
							this.isChangedProductionAccountant &&
							this.isChangedUpm &&
							this.isChangedLineProducer &&
							this.isChangedStudio;
					}
				}
			}
		},
		handleTableHierarchicalDocumentDeliverySign(payload) {
			this.signDocFile(payload);
		},
		handleClickGoBack() {
			if (!isNil(this.$route.query.viaTabDocPackage)) {
				this.$router.push({
					path: `/documents?viaTabDocPackage=1`
				});
			} else {
				this.$router.push({
					path: '/documents'
				});
			}
		},
		signDocFile(item) {
			this.userIdSelected = item.user.id;
			this.selectedItem = item;
			// this.checkPasswordModal=true;
		},
		async reloadDlvsData() {
			try {
				const DocumentId = parseInt(this.newDoc.id, 10);

				this.Dlvs = await getDocumentDeliveries(DocumentId);
				this.fillDlvs();
			} catch (e) {
				console.log(error);
				this.erreur = error;
			}
		},
		fillDlvs() {
			for (let i = 0; i < this.Dlvs.length; i++) {
				if (
					(!this.Dlvs[i].user.name && !this.Dlvs[i].user.firstName) ||
					(this.Dlvs[i].user.name.length === 0 && this.Dlvs[i].user.firstName.length === 0)
				) {
					this.Dlvs[i].fullName = this.Dlvs[i].user.email;
				} else {
					this.Dlvs[i].fullName = this.Dlvs[i].user.name + ' ' + this.Dlvs[i].user.firstName;
				}

				this.Dlvs[i].validatedClass = classDocumentStatus(this.Dlvs[i].status);
				this.Dlvs[i].myValidatedClass = classDocumentStatus(this.Dlvs[i].senderStatus);
			}
		},
		setPublishedDate() {
			if (this.alreadyPublished()) {
				let date =
					this.newDoc.publishedOn.split('T')[0].substring(8) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(5, 7) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(0, 4);
				date = date + ' ' + this.newDoc.publishedOn.substring(11, 13) + ':' + this.newDoc.publishedOn.substring(14, 16);
				return this.FormMSG(57, 'Published by') + ' ' + this.newDoc.publisher.name + ' ' + this.newDoc.publisher.firstName + '    - ' + date;
			} else {
				return 'Not published yet';
			}
		},
		alreadyPublished() {
			const y = new Date('2013-05-23');
			const x = new Date(this.newDoc.publishedOn.substring(0, 10));
			if (x.getTime() > y.getTime()) {
				return true;
			} else {
				return false;
			}
		},
		async viewDocument(item) {
			const { id } = this.$route.params;

			await getDocument(id, {}).then((record) => {
				let fileList = [];

				if (record.type === 11) {
					fileList = record.childDocList.filter((list) => list.userId === null || parseInt(list.userId, 10) === 0);
					fileList = fileList.map((doc) => {
						const SRC = getFileSrc(doc, { contractId: item.contractId, requestId: store.userID(), userId: item.userId });
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							src: `${SRC}?deliveryParentId=${doc.firstDeliveryParentId}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							isHovered: false
						};
					});
				} else {
					const SRC = getFileSrc(record, { imageOnly: false, contractId: item.contractId, requestId: store.userID(), userId: item.userId });
					fileList.push({
						fileName: this.replaceToRightFileNameExt(record.fileName, item.xidSigned),
						type: record.type,
						xid: this.getRightXidDocument(record.xid, item.xidSigned),
						status: record.status,
						src: `${SRC}?deliveryParentId=${record.firstDeliveryParentId}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(record.xid, item.xidSigned),
						ext: getFileExtension(this.getRightXidDocument(record.xid, item.xidSigned)),
						isHovered: false
					});
				}

				this.$previewImages({
					images: fileList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						// showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		viewDocumentCertificate(item) {
			// console.log('in viewdocument certifacte')
			if (this.newDoc.toSign) {
				// needs to generate new doc for specific user, watermark specific or contract template
				//console.log("viewdocument  item.useWatermark && item.watermarkWithUserbefore userId:",item);
				// var tmp = store.userID()

				const urlStr =
					process.env.VUE_APP_GQL +
					'/GetDocumentCertificate/' +
					item.user.id +
					'/' +
					this.newDoc.id +
					'/' +
					this.newDoc.name +
					item.user.name +
					item.user.firstName +
					'Cert.docx';

				// console.log('viewDocumentCertificate url:', urlStr)
				window.open(urlStr); // in new window
			}
			return;
		},
		isPdfFile() {
			// check if file is a pdf
			if (isNil(this.newDoc.fileName) || this.newDoc.fileName.length === 0) {
				return false;
			}
			const fileName = this.newDoc.fileName;
			const nameLen = fileName.length;
			const lastDotPos = fileName.lastIndexOf('.');
			let fileNameSub = false;
			if (lastDotPos === -1) {
				return false;
			} else {
				//Remove +1 if you want the "." left too
				fileNameSub = fileName.substr(lastDotPos + 1, nameLen).toLowerCase();
				if (fileNameSub === 'pdf') {
					return true;
				}
			}
			return false;
		},
		showDeliveries() {
			// copy item content into curTs structure.
			store.state.curDocument = this.newDoc;
			//console.log("item id:", item.id);
			const deliveryLink = `/documents/delivery/${this.newDoc.id.toString()} `;
			// console.log("starting:", deliveryLink);
			this.$router.push({
				path: deliveryLink
			});
		},
		fillUpdatedDocument() {
			let UpdatedDocument = {};
			UpdatedDocument.type = parseInt(this.newDoc.type, 10);
			UpdatedDocument.name = this.newDoc.name;
			UpdatedDocument.fileName = this.newDoc.fileName;
			UpdatedDocument.body = this.newDoc.body.substring(0, 4000);
			UpdatedDocument.userPassword = this.newDoc.userPassword;
			UpdatedDocument.ownerPassword = this.newDoc.ownerPassword;
			UpdatedDocument.xid = this.newDoc.xid;
			UpdatedDocument.useWatermark = this.newDoc.useWatermark;
			UpdatedDocument.watermarkText = this.newDoc.watermarkText;
			UpdatedDocument.watermarkPictureXid = this.newDoc.watermarkPictureXid;
			UpdatedDocument.watermarkPictureFileName = this.newDoc.watermarkPictureFileName;
			UpdatedDocument.watermarkWithUser = this.newDoc.watermarkWithUser;
			UpdatedDocument.toSign = this.newDoc.toSign;
			UpdatedDocument.toEmail = this.newDoc.toEmail;
			UpdatedDocument.toSms = this.newDoc.toSms;
			UpdatedDocument.toWhatsApp = this.newDoc.toWhatsApp;
			UpdatedDocument.availableOnline = this.newDoc.availableOnline;
			UpdatedDocument.requireManagerApproval = this.newDoc.requireManagerApproval;

			return UpdatedDocument;
		},
		async downloadCertificate(item) {
			const { id } = this.$route.params;
			const fileName = 'Certificate' + Date.now().toString() + '.docx';
			const urlStr =
				process.env.VUE_APP_GQL +
				'/GetDocumentCertificate/' +
				item.userId +
				'/' +
				parseInt(id).toString() +
				'/' +
				parseInt(item.contractId).toString() +
				'/' +
				fileName;
			const response = await axios.get(urlStr, {
				responseType: 'arraybuffer'
			});
			const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
		},
		async downloadPDF() {
			const { id } = this.$route.params;

			await getDocument(id, {}).then(async (result) => {
				const urlStr = process.env.VUE_APP_PATH_IMG_URL + result.xid;
				const response = await axios.get(urlStr, {
					responseType: 'arraybuffer'
				});
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = result.xid;
				link.click();
			});
		},
		async curDocUpd() {
			try {
				const UpdatedDocument = this.fillUpdatedDocument();
				const DocumentId = parseInt(this.newDoc.id, 10);

				const result = await updateDocument(DocumentId, UpdatedDocument);
				// console.log({ result });

				this.curDoc = this.newDoc = {};

				await addUpdDocValConfig(this.roleByStepToSend);

				await this.reloadData();

				this.createToastForMobile(this.FormMSG(128, 'Success'), this.FormMSG(135, 'Document updated successfully'));
			} catch (e) {
				console.log(e);

				let myerrorObject = {
					err: ''
				};
				myerrorObject.err = e.message;
				this.erreur = myerrorObject;
			}
		},
		initValuesForNoRecords() {
			this.newDoc.name = ' NEW DOCUMENT';
			this.newDoc.fileName = '';
			this.newDoc.type = parseInt('0', 10);
			this.newDoc.fileName = '';
			this.newDoc.userPassword = '';
			this.newDoc.ownerPassword = '';
			this.newDoc.xid = '';
			this.newDoc.useWatermark = false;
			this.newDoc.watermarkText = '';
			this.newDoc.watermarkPictureXid = '';
			this.newDoc.watermarkPictureFileName = '';
			this.newDoc.watermarkWithUser = false;
			this.newDoc.toSign = false;
			this.newDoc.toEmail = false;
			this.newDoc.toSms = false;
			this.newDoc.toWhatsApp = false;
			this.newDoc.availableOnline = true;
			this.newDoc.body = '';
		},
		async reloadData(computed = true) {
			let documentId = +store.getCurrentDocumentId();
			if (documentId === 0) {
				documentId = +this.$route.params.id;
			}

			const result = await getDocumentList({ documentId });
			this.Docs = _.cloneDeep(result);

			for (let i = 0; i < this.Docs.length; i++) {
				this.Docs[i].fullName = this.Docs[i].publisher.name + ' ' + this.Docs[i].publisher.firstName;
			}

			if (this.Docs.length > 0) {
				if (computed) {
					this.curDoc = this.Docs[0];
					this.newDoc = Object.assign({}, this.curDoc);
				}

				this.isDocumentPackage = this.newDoc.type === 11;
				await this.reloadDlvsData();
			} else {
				this.initValuesForNoRecords();
			}
		},
		async delItem(id) {
			try {
				const documentId = +id;

				await deleteDocument(documentId);

				await this.reloadData();
			} catch (e) {
				this.erreur = e;
				console.log(e);
			}
		},
		rowClicked(item, index) {
			this.currentIdx = (this.currentPage - 1) * this.perPage + index;
			this.lastPublishedOn = this.setPublishedDate();
		},
		removeDocument(xid) {
			// console.log('removing document :', xid)
			let retStr = '';
			let formData = new FormData(); // instantiate it
			formData.append('fileName', xid);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			let urlStr = process.env.VUE_APP_GQL + '/removedocument';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					//console.log("document removed:", retStr);
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
			return retStr;
		},
		removeWmFile() {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(61, 'Are you sure to remove this file ?'), {
					title: this.FormMSG(62, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(63, 'YES'),
					cancelTitle: this.FormMSG(64, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					if (this.newDoc.watermarkPictureFileName && this.newDoc.watermarkPictureFileName.length > 0) {
						// new watermark selected, remove the old file from the server
						this.removeDocument(this.newDoc.watermarkPictureXid);
					}
					this.newDoc.watermarkPictureFileName = '';
					this.newDoc.watermarkPictureXid = '';
					this.curDocUpd();
				})
				.catch((err) => console.log(err));
		},
		submitWmFile(ev) {
			this.isLoading = true;
			let wmImgFile = '';
			if (ev.type === 'drop') {
				wmImgFile = ev.dataTransfer.files[0];
			} else if (ev.type === 'change') {
				wmImgFile = ev.target.files[0];
			}
			//console.log("submitWmFile submit file:", wmImgFile);

			if (this.newDoc.watermarkPictureFileName && this.newDoc.watermarkPictureFileName.length > 0) {
				// new watermark selected, remove the old file from the server
				this.removeDocument(this.newDoc.watermarkPictureXid);
			}

			this.newDoc.watermarkPictureFileName = wmImgFile.name;
			this.formDataObj = new FormData();
			this.formDataObj.append('uploadimage', wmImgFile);
			this.formDataObj.append('fileName', wmImgFile.name);
			this.formDataObj.append('fileType', wmImgFile.type);
			this.formDataObj.append('resize', '1024');
			const urlStr = process.env.VUE_APP_GQL + '/upload';
			//console.log("submit file before axios call:", urlStr);

			// console.log('wmImgFIle submitWmFile:', wmImgFile)
			// var fd = this.formDataObj
			axios
				.post(urlStr, this.formDataObj, {
					headers: {
						//...axios.defaults.headers,
						//  'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
						//fd.getHeaders()
					}
				})
				.then((result) => {
					//window.location.reload();
					// console.log('boundary:', this.formDataObj.getAll('uploadimage'))
					this.newDoc.watermarkPictureXid = result.data;
					// console.log('submitWmFile success this.newDoc.watermarkPictureXid:', this.newDoc.watermarkPictureXid)
					// save document with update
					this.curDocUpd();
					this.$refs['wmFileFieldId'].reset();
				})
				.catch(function () {
					console.log('boundary:', this.formDataObj.getAll('uploadimage'));
					// console.log(' submitWmFile FAILURE!!')
				})
				.finally(() => (this.isLoading = false));
		},
		removePdfFile() {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(61, 'Are you sure to remove this file ?'), {
					title: this.FormMSG(62, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(63, 'YES'),
					cancelTitle: this.FormMSG(64, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					if (this.newDoc.xid && this.newDoc.xid.length > 0) {
						//console.log("in submitDocFile remove old doc:", this.newDoc.xid)
						// new document selected, remove the old file from the server
						this.removeDocument(this.newDoc.xid);
						if (this.newDoc.xidGenerated && this.newDoc.xidGenerated.length > 0) {
							// remove also the previous xidGenerated if any
							this.removeDocument(this.newDoc.xidGenerated);
						}
					}
					this.newDoc.fileName = '';
					this.newDoc.xid = '';
					this.newDoc.xidGenerated = '';
					this.curDocUpd();
				})
				.catch((err) => console.log(err));
		},
		submitDocFile(ev) {
			this.isLoading = true;
			let docFil = '';
			if (ev.type === 'drop') {
				docFil = ev.dataTransfer.files[0];
			} else if (ev.type === 'change') {
				docFil = ev.target.files[0];
			}
			//console.log("submitDocFile submit file:", docFil);
			let formData = new FormData();
			formData.append('uploadimage', docFil);
			formData.append('fileName', docFil.name);
			if (this.newDoc.xid && this.newDoc.xid.length > 0) {
				//console.log("in submitDocFile remove old doc:", this.newDoc.xid)
				// new document selected, remove the old file from the server
				this.removeDocument(this.newDoc.xid);
				if (this.newDoc.xidGenerated && this.newDoc.xidGenerated.length > 0) {
					// remove also the previous xidGenerated if any
					this.removeDocument(this.newDoc.xidGenerated);
				}
			}

			this.newDoc.fileName = docFil.name;
			formData.append('fileType', docFil.type);
			//console.log("in submitdocfile, fileName:", this.newDoc.fileName);
			const urlStr = process.env.VUE_APP_GQL + '/upload';
			// console.log('in submitdocfile, urlStr:', urlStr)
			// console.log('in submitdocfile, formData:', formData)
			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
					//console.log("submitDocFile success result:", result);
					this.newDoc.xid = result.data;
					this.newDoc.xidGenerated = '';
					// save document with update
					this.curDocUpd();
					this.$refs['pdfFileId'].reset();
				})
				.catch(function () {
					console.log(' submitDocFile FAILURE!!');
				})
				.finally(() => (this.isLoading = false));
		},
		getIconLucide(name) {
			return icons[name];
		},
		handlePreviewDoc() {
			const list = [
				{
					...this.newDoc,
					src: `${process.env.VUE_APP_GQL}/images/${this.newDoc.xid}`,
					thumb: '',
					ext: this.newDoc.fileName.split('.')[1]
				}
			];

			const index = 0;

			this.$previewImages({
				images: list,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		handlePreviewWm() {
			if (isNil(this.newDoc.watermarkPictureXid) || (!isNil(this.newDoc.watermarkPictureXid) && this.newDoc.watermarkPictureXid.length === 0)) return;
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${this.newDoc.watermarkPictureXid}`;
			this.openPictureModal = true;
		},
		getLabelStep(status, senderStatus, currentStep, requireManagerApproval) {
			if (senderStatus === 5) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}
			if (currentStep === 0) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}

			if (currentStep === 1) {
				if (!requireManagerApproval) {
					return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
				}
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(84, 'step 1');
			}
			if (currentStep === 2) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(85, 'step 2');
			}
			if (currentStep === 3) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(86, 'step 3');
			}
		},
		handleChangeManagerApproval(payload) {
			// console.log({ managerApproval: payload });
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>
